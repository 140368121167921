exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-of-conduct-js": () => import("./../../../src/pages/code-of-conduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-contest-js": () => import("./../../../src/pages/contest.js" /* webpackChunkName: "component---src-pages-contest-js" */),
  "component---src-pages-contest-privacy-js": () => import("./../../../src/pages/contest-privacy.js" /* webpackChunkName: "component---src-pages-contest-privacy-js" */),
  "component---src-pages-contest-rules-js": () => import("./../../../src/pages/contest-rules.js" /* webpackChunkName: "component---src-pages-contest-rules-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-notice-for-visitors-js": () => import("./../../../src/pages/privacy-notice-for-visitors.js" /* webpackChunkName: "component---src-pages-privacy-notice-for-visitors-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-third-party-services-js": () => import("./../../../src/pages/third-party-services.js" /* webpackChunkName: "component---src-pages-third-party-services-js" */),
  "component---src-pages-venue-js": () => import("./../../../src/pages/venue.js" /* webpackChunkName: "component---src-pages-venue-js" */),
  "component---src-pages-volunteers-js": () => import("./../../../src/pages/volunteers.js" /* webpackChunkName: "component---src-pages-volunteers-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

